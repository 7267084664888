import styled from "styled-components";

export const DarkContainer = styled.div`
  background-color: var(--dark-color);
  color: var(--white-color);
`;

export const MainWrapper = styled.div`
  max-width: var(--layout-max-width);
  width: 100%;
  padding: 16px;
  margin: 0 auto;
`;
