import { DarkContainer, MainWrapper } from "./Elements";

export const Footer = () => {
  return (
    <DarkContainer>
      <MainWrapper>
        <p>tecum.solutions</p>
        <p>Costa Rica</p>
      </MainWrapper>
    </DarkContainer>
  );
};
