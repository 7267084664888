import styled from "styled-components";
import { Content } from "./layout/Content";
import { Footer } from "./layout/Footer";
import { Header } from "./layout/Header";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

function App() {
  return (
    <AppWrapper>
      <Header />
      <Content>
        <h1 style={{ marginBottom: '1rem'}}>Tecum Website</h1>
        <p>Sitio en construcción / We are under construction</p>
      </Content>
      <Footer />
    </AppWrapper>
  );
}

export default App;
