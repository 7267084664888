import { ReactNode } from "react";
import styled from "styled-components";
import { MainWrapper } from "./Elements";

const ContentWrapper = styled.div`
  height: 100%;
`;

export const Content = ({ children }: { children: ReactNode }) => {
  return (
    <ContentWrapper>
      <MainWrapper>{children}</MainWrapper>
    </ContentWrapper>
  );
};
