import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
* {
  padding: 0;
  margin: 0;
}

body {
  --dark-color: #172F45;
  --white-color: #F6F7F8;
  --success-color: #D6FF79;
  --danger-color: #F90093;
  --warning-color: #FCA311;
  --gray-color: #808F85;
  --wine-color: #530257;

  --font-family: 'Antonio', sans-serif;
  --font-regular: 400;
  --font-bold: 700;
  --font-line-height: 1.5;

  --layout-max-width: 1024px;

  font-family: var(--font-family);
  font-size: 1.2rem;
  font-weight: var(--font-regular);
  color: var(--dakr-color);
  line-height: var(--font-line-height);
}
`;

const root = ReactDOM.createRoot(
  document.getElementById("tecum") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
