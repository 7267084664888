import { DarkContainer, MainWrapper } from "./Elements";

export const Header = () => {
  return (
    <DarkContainer>
      <MainWrapper>
        <div>
          <a href="/" title="tecum.solutions">
            <img src="/images/logo-dark-mode.png" alt="tecum.solutions" />
          </a>
        </div>
      </MainWrapper>
    </DarkContainer>
  );
};
